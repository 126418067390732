<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          :color="updateButtonColor ? updateButtonColor : 'primary'"
          class="mr-2"
          outlined
          fab
          @click="update"
        >
          {{ updateIcon }}
        </v-icon>
      </template>
      <span>{{ updateToolTipTitle ? updateToolTipTitle : "Update" }}</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          :color="deleteButtonColor ? deleteButtonColor : 'danger'"
          @click="destroy"
        >
          {{ deleteIcon }}
        </v-icon>
      </template>
      <span>{{ deleteToolTipTitle ? deleteToolTipTitle : "Delete" }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "ActionsButtons",
  props: {
    updateFunction: { type: Function, required: false },
    deleteFunction: { type: Function, required: false },
    deleteByObjectFunction: { type: Function, required: false },
    updateIcon: { type: String, required: false },
    deleteIcon: { type: String, required: false },
    formData: { type: Object, required: true },
    updateButtonColor: { type: String, required: false },
    deleteButtonColor: { type: String, required: false },
    updateToolTipTitle: { type: String, required: false },
    deleteToolTipTitle: { type: String, required: false },
  },

  data() {
    return {
      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },

  methods: {
    update() {
      this.updateFunction(this.formData);
    },

    destroy() {
      this.deleteFunction(this.formData.id, this.formData);
    },
  },

  created() {},
};
</script>
